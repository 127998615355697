<template>
  <div class="user-table">
    <div class="dz-flexalign-center top">
      <div class="title">产量分配</div>
      <div v-if="errCode" class="dz-flexitem-1">
        {{ codeToStr[errCode] }}
      </div>
    </div>
    <template v-if="userData.length">
      <div class="table-th table-tr">
        <div>人员</div>
        <div>始末设备</div>
        <div></div>
      </div>
      <div
        v-for="(item, index) in userData"
        :key="index"
        class="table-tr"
        :class="{ error: item.errCode }"
      >
        <div>
          <el-select
            v-model="item.user"
            placeholder="请选择"
            @change="handleChange"
            filterable
            size="small"
          >
            <el-option
              v-for="(item, key) in userList"
              :key="key"
              :label="item.name"
              :value="key"
            >
            </el-option>
          </el-select>
        </div>

        <div class="dz-flexalign-center">
          <div class="dz-flexalign-center" style="width: 64px">
            <el-input
              class="dz-input-number"
              v-model="item.start"
              @change="handleChange"
              @input="handleInput(index, 'start')"
              size="small"
            ></el-input>

            <el-input
              class="dz-input-number"
              v-model="item.startAdd"
              @change="handleAddChange(index, 'start')"
              v-if="item.startAdd"
              size="small"
            ></el-input>
          </div>
          <span style="color: #e5e6e8; margin: 0 2px">-</span>
          <div class="dz-flexalign-center" style="width: 64px">
            <el-input
              class="dz-input-number"
              v-model="item.end"
              @change="handleChange"
              @input="handleInput(index, 'end')"
              size="small"
            ></el-input>
            <el-input
              class="dz-input-number"
              v-model="item.endAdd"
              @change="handleAddChange(index, 'end')"
              v-if="item.endAdd"
              size="small"
            ></el-input>
          </div>
        </div>
        <div>
          <i class="el-icon-top" @click="changeOrder(index, index - 1)"></i>
          <i class="el-icon-bottom" @click="changeOrder(index, index + 1)"></i>
        </div>
        <i class="iconfont icon-jianhao" @click="del(index)"></i>
      </div>
      <div class="dz-flexspace-between">
        <div class="add-btn" @click="reset">+新增</div>
        <div class="add-btn" style="color: red" @click="clear">清空</div>
      </div>
    </template>
    <el-button
      type="primary"
      v-else
      style="width: 100%"
      @click="startDistribute"
      >开始分配</el-button
    >

    <el-dialog
      title="新增人员"
      :visible.sync="show"
      width="300px"
      :close-on-click-modal="false"
    >
      <el-form label-width="100px">
        <el-form-item label="人员名称">
          <el-cascader
            v-model="addForm.user"
            placeholder="请选择人员"
            :options="classList"
            :show-all-levels="false"
            filterable
          />
        </el-form-item>
        <el-form-item label="开始设备号">
          <el-input
            v-model="addForm.start"
            @input="handleAddInput('start')"
            placeholder="请输入开始设备号"
            @keydown.enter.native="$refs.endDec.focus()"
          ></el-input>
        </el-form-item>
        <el-form-item label="结束设备号">
          <el-input
            ref="endDec"
            v-model="addForm.end"
            @input="handleAddInput('end')"
            placeholder="请输入结束设备号"
            @keydown.enter.native="confirm"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button @click="show = false">取消</el-button>
          <el-button type="primary" @click="confirm">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="开始分配"
      :visible.sync="distributeShow"
      :close-on-click-modal="false"
      width="fit-content"
    >
      <div class="dz-flex">
        <div
          v-for="(dItem, dIndex) in distributeUserList"
          :key="dIndex"
          style="width: 250px"
        >
          <div style="width: 100%; text-align: center; padding: 10px">
            {{ dItem.label }}
          </div>
          <div class="table-th table-tr">
            <div>人员</div>
            <div>始末设备</div>
          </div>
          <div
            v-for="(item, index) in dItem.children"
            :key="index"
            class="table-tr"
          >
            <div>
              {{ item.label }}
            </div>

            <div class="dz-flexalign-center">
              <div class="dz-flexalign-center" style="width: 64px">
                <el-input
                  type="number"
                  class="dz-input-number"
                  :class="`disInput-start-${dIndex}`"
                  v-model="item.start"
                  @keydown.enter.native="keydownTab(dIndex, index, 'end')"
                  size="mini"
                ></el-input>
              </div>
              <span style="color: #e5e6e8; margin: 0 2px">-</span>
              <div class="dz-flexalign-center" style="width: 64px">
                <el-input
                  type="number"
                  class="dz-input-number"
                  :class="`disInput-end-${dIndex}`"
                  v-model="item.end"
                  @keydown.enter.native="keydownTab(dIndex, index, 'start')"
                  size="mini"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dz-flex" style="justify-content: flex-end; margin-top: 15px">
        <el-button @click="distributeShow = false">取消</el-button>
        <el-button type="primary" @click="distributeConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["userList"],
  data() {
    return {
      classList: [],
      addForm: {
        user: [],
        start: "",
        end: "",
      },
      show: false,
      codeToStr: {
        1: "起始值大于结束值",
        2: "设备重复分布",
        3: "有设备未选择品种",
        4: "数据格式不正确",
      },
      errCode: 0,
      userData: [],
      distributeShow: false,
      distributeUserList: [],
    };
  },
  methods: {
    keydownTab(di, i, cls) {
      if (
        i === this.distributeUserList[di].children.length - 1 &&
        cls === "start"
      ) {
        di = di - 0 + 1;
        i = -1;
      }
      document
        .querySelectorAll(`.disInput-${cls}-${di} > input`)
        [cls === "start" ? i + 1 : i].focus();
    },
    changeOrder(index, index2) {
      if (index2 < 0 || index2 === this.userData.length) {
        return;
      }
      this.userData.splice(index2, 0, this.userData.splice(index, 1)[0]);
      this.handleChange();
    },
    distributeConfirm() {
      this.$confirm("确定分配？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let userData = [];
          this.distributeUserList.forEach((dItem) => {
            dItem.children.forEach((cItem) => {
              if (cItem.start && cItem.end) {
                userData.push({
                  user: cItem.label,
                  start:
                    cItem.start.indexOf(".") !== -1
                      ? cItem.start.split(".")[0]
                      : cItem.start,
                  end:
                    cItem.end.indexOf(".") !== -1
                      ? cItem.end.split(".")[0]
                      : cItem.end,
                  errCode: 0,
                  startAdd:
                    cItem.start.indexOf(".") !== -1
                      ? "0." + cItem.start.split(".")[1]
                      : 0,
                  endAdd:
                    cItem.end.indexOf(".") !== -1
                      ? "0." + cItem.end.split(".")[1]
                      : 0,
                });
              }
            });
          });
          this.distributeShow = false;
          userData.sort((a, b) => a.start - b.start);
          this.userData = userData;
          this.handleChange();
        })
        .catch(() => {});
    },
    startDistribute() {
      this.distributeUserList = this.classList.map((dItem) => {
        return {
          children: dItem.children.map((cItem) => {
            return {
              label: cItem.label,
              start: "",
              end: "",
            };
          }),
          label: dItem.label,
        };
      });
      this.distributeShow = true;
    },
    clear() {
      this.$confirm("是否清空所有人员?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.userData = [];
          this.handleChange();
        })
        .catch(() => {});
    },
    //初始化
    init(data) {
      this.userData = data || [];
      this.handleChange();
    },
    //检查
    check() {
      return this.errCode ? this.codeToStr[this.errCode] : null;
    },
    del(i) {
      this.$confirm("是否删除该人员?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.userData.splice(i, 1);
          this.handleChange();
        })
        .catch(() => {});
    },
    confirm() {
      if (!this.addForm.user.length) {
        this.$message.error("请选择人员");
        return;
      }
      if (!this.addForm.start) {
        this.$message.error("请输入开始设备号");
        return;
      }
      if (!this.addForm.end) {
        this.$message.error("请输入结束设备号");
        return;
      }
      if (this.addForm.start - 0 > this.addForm.end - 0) {
        this.$message.error("起始值大于结束值");
        return;
      }
      this.show = false;
      this.addForm.user = this.addForm.user[this.addForm.user.length - 1];
      this.userData.push({
        ...this.addForm,
        errCode: 0,
        startAdd: 0,
        endAdd: 0,
      });
      this.handleChange();
    },
    handleAddInput(key) {
      this.addForm[key] = this.addForm[key].replace(/[^0-9]/g, "");
    },
    reset() {
      this.addForm = {
        user: [],
        type: "",
        start: "",
        end: "",
      };
      this.show = true;
    },
    handleAddChange(index, key) {
      if (key === "start") {
        if (
          Number(this.userData[index].startAdd) +
            Number(this.userData[index - 1].endAdd) >
          1
        ) {
          this.userData[index].startAdd = 1 - this.userData[index - 1].endAdd;
        }
      } else {
        if (
          Number(this.userData[index].endAdd) +
            Number(this.userData[index + 1].startAdd) >
          1
        ) {
          this.userData[index].endAdd = 1 - this.userData[index + 1].startAdd;
        }
      }
      this.handleChange();
    },
    handleChange() {
      this.errCode = 0;
      for (let i = 0; i < this.userData.length; i++) {
        let item = this.userData[i];
        item.errCode = 0;
        if (!item.start || !item.end) {
          this.errCode = item.errCode = 4;
        }

        if (item.start - 0 > item.end - 0) {
          this.errCode = item.errCode = 1;
        }
        if (i !== this.userData.length - 1) {
          if (item.end - 0 > this.userData[i + 1].start) {
            this.errCode = item.errCode = 2;
          } else {
            if (
              item.end - 0 == this.userData[i + 1].start &&
              (this.userData[i + 1].startAdd == 0 || item.endAdd == 0)
            ) {
              if (this.userData[i + 1].startAdd == 0 && item.endAdd == 0) {
                item.endAdd = 0.5;
                this.userData[i + 1].startAdd = 0.5;
              } else {
                if (this.userData[i + 1].startAdd == 0) {
                  item.endAdd = 0;
                  item.end = Number(this.userData[i + 1].start) - 1;
                } else {
                  item.startAdd = 0;
                  this.userData[i + 1].start = Number(item.end) + 1;
                }
              }
            }
          }
        }
      }

      if (!this.errCode) {
        for (let item of this.userData) {
          item.start = Number(item.start);
          item.startAdd = Number(item.startAdd);
          item.end = Number(item.end);
          item.endAdd = Number(item.endAdd);
        }
        this.$emit("change", this.userData);
      }
    },
    handleInput(index, key) {
      this.userData[index][key] = this.userData[index][key].replace(
        /[^0-9]/g,
        ""
      );
      if (this.userData[index][key] == 0) {
        this.userData[index][key] = "";
      }
    },
    resetClassList() {
      //根据班次划分人员
      let classList = [];
      for (let key in this.userList) {
        if (this.userList.hasOwnProperty(key)) {
          if (this.userList[key].work.join(",").indexOf("挡车") === -1) {
            continue;
          }
          let mate = classList.find((uItem) => {
            return uItem.value === this.userList[key].class;
          });
          if (mate) {
            mate.children.push({
              value: key,
              label: this.userList[key].name,
            });
          } else {
            if (this.userList[key].class) {
              classList.push({
                value: this.userList[key].class,
                label: this.userList[key].class,
                children: [
                  {
                    value: key,
                    label: this.userList[key].name,
                  },
                ],
              });
            }
          }
        }
      }
      this.classList = classList;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.icon-jianhao {
  position: absolute;
  right: 10px;
  color: #747677;
  cursor: pointer;
}
.user-table {
  flex: 1;
  position: relative;
  background: #fff;
  border-radius: 6px;
  padding: 13px 16px 16px 16px;
  .title {
    font-size: 16px;
    line-height: 20px;
  }
  .top {
    margin-bottom: 10px;
    .dz-flexitem-1 {
      text-align: right;
      font-size: 12px;
      color: red;
    }
  }
}
.table-th {
  height: 32px;
  background: #f7f8fa;
  font-weight: bold;
}
.table-tr {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  width: calc(100% - 20px);
  > div {
    font-size: 12px;

    text-align: center;
    &:first-child {
      flex: 1;
      margin-right: 8px;
    }
    &:nth-child(2) {
      width: 136px;
    }
    &:nth-child(3) {
      width: 60px;
      i {
        font-weight: bold;
        cursor: pointer;
        font-size: 16px;
        &:last-child {
          margin-left: 5px;
        }
      }
    }
  }
}
</style>
<template>
  <div class="luosha">
    <div class="title">落纱分配</div>
    <div class="user-list">
      <div
        v-for="(item, index) in luoshaData"
        :key="index"
        class="user-item"
        @click="item.enable = !item.enable"
        :class="{ actived: item.enable }"
      >
        <span> {{ item.name }}</span>

        <i class="el-icon-error" @click.stop="luoshaData.splice(index, 1)" />
      </div>
    </div>
    <div class="add-btn" @click="add">+新增</div>
    <el-dialog
      title="落砂分配"
      :visible.sync="show"
      width="300px"
      :close-on-click-modal="false"
    >
      <el-form label-width="50px">
        <el-form-item label="人员">
          <el-select
            v-model="addLuosha"
            placeholder="请选择人员"
            filterable
            multiple
          >
            <el-option
              v-for="(item, key) in luoshaList"
              :key="key"
              :label="item.name"
              :value="key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-button @click="show = false">取消</el-button>
          <el-button type="primary" @click="confirm">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["userList"],
  data() {
    return {
      luoshaList: {},
      luoshaData: [],
      show: false,
      addLuosha: [],
    };
  },
  methods: {
    add() {
      this.addLuosha = [];
      for (let item of this.luoshaData) {
        this.addLuosha.push(item.user);
      }
      this.show = true;
    },
    confirm() {
      for (let user of this.addLuosha) {
        if (
          !this.luoshaData.find((item) => {
            return item.user === user;
          })
        ) {
          this.luoshaData.push({
            name: this.userList[user].name,
            user,
            enable: true,
          });
        }
      }
      for (let i = this.luoshaData.length - 1; i >= 0; i--) {
        if (
          !this.addLuosha.find((user) => {
            return this.luoshaData[i].user === user;
          })
        ) {
          this.luoshaData.splice(i, 1);
        }
      }

      this.show = false;
    },
    init(data) {
      for (let key in this.userList) {
        if (this.userList[key].work.join(",").indexOf("落纱") > -1) {
          this.luoshaList[key] = this.userList[key];
        }
      }
      if (data) {
        this.luoshaData = data;
        return;
      }
      this.luoshaData = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.el-icon-error {
  position: absolute;
  right: 0;
  color: red;
  font-size: 12px;
  top: -9px;
  right: -9px;
  display: none;
  padding: 3px;
}
.add-btn {
  font-size: 12px;
  color: #0089ff;
  margin-top: 2px;
  cursor: pointer;
  width: fit-content;
  margin-left: 6px;
}
.luosha {
  width: 138px;
  background: #fff;
  border-radius: 6px;
  padding: 13px 10px 16px 10px;
  margin-left: 10px;
  .title {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
    margin-left: 6px;
  }
}
.user-list {
  overflow: hidden;
  padding-top: 6px;
  padding: 6px;
}
.user-item {
  position: relative;
  padding: 0 4px;

  cursor: pointer;
  line-height: 32px;
  border-radius: 6px;
  border: 1px solid #e5e6e8;
  text-align: center;
  float: left;
  white-space: nowrap;
  font-size: 12px;
  margin-bottom: 8px;
  &:hover {
    .el-icon-error {
      display: block;
    }
  }
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 38px;
    display: block;
  }
  &:nth-child(2n) {
    margin-left: 8px;
  }
}
.actived {
  background: #0089ff;
  border-color: #0089ff;
  color: #fff;
}
</style>
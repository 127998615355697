import Vue from 'vue';
import axios from 'axios';
import { Loading, Message } from 'element-ui';
let ajax = axios.create({
    baseURL: (process.env.NODE_ENV === 'development' || window.location.href.indexOf('test') !== -1) ? "//api-digital.fzmskj.com/system/yida/self/" : "//api-digital.fzmskj.com/system/yida/huada/"
});
let loadingInstance;
let ajaxNum = 0;


ajax.interceptors.request.use(
    function (config) {
        ajaxNum++;
        let acc = sessionStorage.getItem('acc');
        if (!acc) {
            acc = "Ez3bi/gVPNo36pNpmlDIbcWl1i5Px4BaKCuTmMP42Va5sDvzFVZVocJjqgzKmysTwxEiacyuff4Uguk4iYjm5Kef+UKiP4dKgXvdSUa0562shfg4JNzqKlV/sPR729S5B+nzoVEziS/tBn6h1FBeXpkZV5PVFKUYT1FuZz9gv0l7Q7naN1EqmRboc6BXl8wlCjQl3RNKafyOHfOdhWm5ehbDu70t+2FwCDYOVX2bGmP2hd7X/1P3iONsT5oeZVSwfZMvFS8usYCBIk3jS9PKZr82moD+cBqpx5lbKCtqT287SbCrzWdqJmM1AmF4gFQhony8tm5qhfJgoqKS+l3DbA=="
        }
        config.headers.acc = acc;
        if (ajaxNum === 1) {
            loadingInstance = Loading.service({ background: 'transparent' });
        }
        return config;
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 添加响应拦截器
ajax.interceptors.response.use(
    (res) => {
        ajaxNum--;
        if (ajaxNum === 0) {
            loadingInstance.close();
        }
        if(res.data.type){
            return res.data;
        }
        const code = res.data.code || 200;
        if (code !== 200) {
            Message({
                type: 'error',
                message: res.data.msg
            });
            return Promise.reject(res.data.data);
        }
        return res.data.data
    },
    (error) => {
    }
);

Vue.prototype.$ajax = ajax;
<template>
  <div>
    <h1 v-if="mode === 'edit'" style="color:red;font-size:30px;margin-bottom:10px">该页面仅支持修改数据，切勿新增数据</h1>
    <div class="dz-flex" v-if="loaded">
      <div class="source">
        <!-- 主表 -->
        <div
          class="source-title"
          @click="recordModeCount++"
          :style="{ color: recordModeCount > 6 ? 'red' : '' }"
        >
          产量明细
        </div>
        <el-table
          :data="sourceData"
          border
          style="width: 100%"
          :cell-style="cellStyle"
          cell-class-name="dz-cell-style"
          header-cell-class-name="dz-cell-style dz-header-cell-style"
          stripe
        >
          <el-table-column prop="no" label="设备" width="40"> </el-table-column>
          <el-table-column label="交班" width="78">
            <template slot-scope="scope">
              <input
                v-model="scope.row.after"
                class="table-input table-input1"
                @keydown.enter="handleEnter(scope.$index, 'table-input1')"
                @change="totalCount"
              />
            </template>
          </el-table-column>
          <el-table-column label="接班" width="78">
            <template slot-scope="scope">
              <input
                v-model="scope.row.before"
                class="table-input table-input2"
                @keydown.enter="handleEnter(scope.$index, 'table-input2')"
                @change="totalCount"
              />
            </template>
          </el-table-column>
          <el-table-column label="产量" width="66">
            <template slot-scope="scope"
              >{{ scope.row.after ? scope.row.after - scope.row.before : 0 }}
            </template>
          </el-table-column>
          <el-table-column label="品种" prop="type" width="72">
            <template slot-scope="scope">
              <div
                style="
                  max-width: 70px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
                :title="scope.row.type"
              >
                {{ scope.row.type }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="人员">
            <template slot-scope="scope">
              {{
                scope.row.distributions
                  .map(
                    (item) =>
                      `${userList[item.user].name}${
                        item.proportion === 1 ? "" : "(" + item.proportion + ")"
                      }`
                  )
                  .join("、")
              }}
            </template>
          </el-table-column>
        </el-table>
        <el-button type="text" style="color: #0089ff" @click="changeNumber"
          >调整设备数量</el-button
        >
      </div>
      <div class="dz-flexitem-1">
        <!-- 提交数据 -->
        <div class="fixed-bar dz-flexalign-center">
          <el-date-picker
            v-model="base.date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            size="small"
            style="width: 144px"
            @change="changeDate"
          >
          </el-date-picker>
          <el-select
            :value="base.shift"
            placeholder="选择班次"
            size="small"
            style="width: 106px; margin-left: 10px"
            @change="changeShift"
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in shiftList"
              :key="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            :value="base.process"
            placeholder="选择工序"
            size="small"
            style="width: 106px; margin-left: 10px"
            @change="changeProcess"
          >
            <el-option
              :label="item.label"
              :value="item.value"
              v-for="item in processList"
              :key="item.value"
            >
            </el-option>
          </el-select>
          <div class="dz-flexitem-1"></div>
          <el-button size="small" @click="save">暂存</el-button>
          <el-button type="primary" size="small" @click="submit"
            >审核确认</el-button
          >
        </div>
        <!-- 操作数据 -->

        <div class="dz-flex">
          <type-table
            :typeList="typeList"
            @change="typeChange"
            :sourceData="sourceData"
            ref="typeTable"
          />
          <qingshu-table
            :userList="userList"
            :typeList="typeList"
            :sourceData="sourceData"
            ref="qingshuTable"
            v-if="base.process === '1-清梳'"
            @change="qingshuChange"
          />
          <user-table
            :userList="userList"
            @change="userChange"
            ref="userTable"
            v-else
          />
          <luosha-table
            v-if="base.process === '4-细纱'"
            ref="luoshaTable"
            :userList="userList"
          />
        </div>
        <!-- 统计 -->
        <div class="count">
          <div class="title">
            日统计<span v-if="hasErr">人员总产量与品种总产量不相等</span>
          </div>
          <div class="dz-flex">
            <div>
              <el-table
                :data="countByType"
                border
                cell-class-name="dz-cell-style"
                header-cell-class-name="dz-cell-style dz-header-cell-style"
                stripe
              >
                <el-table-column prop="type" label="品种" width="100">
                </el-table-column>
                <el-table-column label="产量" width="168">
                  <template slot-scope="scope"
                    >{{ scope.row.count.toFixed(2) }}
                    <span v-if="scope.row.public"
                      >（充公：{{ scope.row.public.toFixed(2) }}）</span
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <el-table
              :data="countByUser"
              border
              class="dz-flexitem-1"
              style="margin-left: 40px"
              cell-class-name="dz-cell-style"
              header-cell-class-name="dz-cell-style dz-header-cell-style"
              stripe
            >
              <el-table-column label="人员" width="100">
                <template slot-scope="scope"
                  >{{ userList[scope.row.user].name }}
                </template>
              </el-table-column>
              <el-table-column prop="type" label="品种" width="150">
              </el-table-column>
              <el-table-column label="产量">
                <template slot-scope="scope"
                  >{{ scope.row.count.toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column label="工资">
                <template slot-scope="scope"
                  >{{ scope.row.money.toFixed(2) }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>

      <el-dialog title="审核确认" :visible.sync="submitShow" width="570px">
        <div class="tip">
          <i
            class="el-icon-warning"
          />确认后数据将传入数据库，不再支持修改，请谨慎核对
        </div>
        <div style="color: #171a1d" v-if="submitShow">
          {{ base.date }}
          {{
            shiftList.find((item) => {
              return item.value == base.shift;
            }).label
          }}
          <span v-if="hasErr" style="color: #ff3131; margin-left: 28px"
            >校验不通过：人员总产量与品种总产量不相等</span
          >
        </div>
        <div style="width: 270px; margin: 13px auto 0 auto">
          <el-table
            :data="countByType"
            border
            cell-class-name="dz-cell-style"
            header-cell-class-name="dz-cell-style dz-header-cell-style"
            stripe
          >
            <el-table-column prop="type" label="品种" width="100">
            </el-table-column>
            <el-table-column label="产量">
              <template slot-scope="scope"
                >{{ scope.row.count.toFixed(2) }}
                <span v-if="scope.row.public"
                  >（充公：{{ scope.row.public.toFixed(2) }}）</span
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="margin-top: 25px; text-align: right">
          <el-button @click="submitShow = false" size="small">取消</el-button>
          <el-button type="primary" size="small" @click="lastSubmit"
            >审核通过</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import typeTable from "@/components/typeTable.vue";
import userTable from "@/components/userTable.vue";
import luoshaTable from "@/components/luoshaTable.vue";
import qingshuTable from "@/components/qingshuTable.vue";
import dayjs from "dayjs";
export default {
  data() {
    return {
      mode: "create",
      typeToAddr: {
        "1-清梳": "qsdeDatas ",
        "3-粗纱": "csdeDatas",
        "4-细纱": "xsdeDatas",
        "2-并条": "btdeDatas",
        "5-自络": "zrdeDatas",
      },
      // 记录模式数据  大于6则不进行产量/工资数据纪录
      recordModeCount: 0,
      // 记录列表
      recordList: [],
      //是否加载完成
      loaded: false,
      //工序
      processList: [],
      //班序
      shiftList: [],
      //表格数据
      sourceData: [],
      //种类
      typeList: [],
      //员工
      userList: {},
      //种类统计
      countByType: [],
      //员工统计
      countByUser: [],
      //是否有错误
      hasErr: false,
      //基础信息 时间/班次/工序
      base: {
        date: "",
        shift: "",
        process: "4-细纱",
      },
      submitShow: false,
    };
  },
  components: {
    typeTable,
    userTable,
    luoshaTable,
    qingshuTable,
  },
  created() {
    this.mode = this.$route.query.mode || "create";
    this.init();
  },
  methods: {
    //日期
    changeDate() {
      if (this.mode === "edit") {
        Promise.all([this.getTypeList(), this.getUserList()]).then(async () => {
          this.$ajax
            .post("mrbcshbDatas", {
              gx: this.base.process,
              pageSize: 10,
              rq: [
                new Date(this.base.date + " 00:00:00").getTime(),
                new Date(this.base.date + " 00:00:00").getTime(),
              ],
              bc: this.base.shift,
            })
            .then((res) => {
              if (res.records.length) {
                let data = JSON.parse(res.records[0].dbxx);
                this.sourceData.splice(0, this.sourceData.length);
                for (let item of data.sourceData) {
                  this.sourceData.push(item);
                }
                this.$refs.luoshaTable &&
                  this.$refs.luoshaTable.init(data.luoshaData);

                this.$refs.userTable &&
                  this.$refs.userTable.init(data.userData);
                this.$refs.typeTable &&
                  this.$refs.typeTable.init(data.typeData);

                this.$refs.qingshuTable &&
                  this.$refs.qingshuTable.init(
                    data.qingshuData,
                    data.paibaoData
                  );
                this.totalCount();
              } else {
                this.sourceData.splice(0, this.sourceData.length);
                this.$refs.luoshaTable && this.$refs.luoshaTable.init();

                this.$refs.userTable && this.$refs.userTable.init();
                this.$refs.typeTable && this.$refs.typeTable.init();

                this.$refs.qingshuTable && this.$refs.qingshuTable.init();
                this.totalCount();
              }
            });
        });
      }
    },
    //初始化
    init() {
      this.base.date = this.getToday();
      this.base.shift = "1-早班";
      Promise.all([
        this.getShiftList(),
        this.getTypeList(),
        this.getProcessList(),
        this.getUserList(),
        this.getRecordList(),
      ]).then(async () => {
        this.loaded = true;
        this.$nextTick(() => {
          let draft = localStorage.getItem("draft" + this.base.process);
          this.$refs.userTable && this.$refs.userTable.resetClassList();
          if (draft) {
            this.initFromDraft(JSON.parse(draft));
          } else {
            this.initFromRemote();
          }
        });
      });
    },
    //清零
    toZero(i) {
      this.$confirm(`确认清零？清零后无法恢复`, "温馨提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.sourceData[i].after = 0;
          this.sourceData[i].before = 0;
          this.handleChange();
        })
        .catch(() => {});
    },
    //从草稿暂存中初始化
    initFromDraft(draft) {
      this.sourceData.splice(0, this.sourceData.length);
      for (let item of draft.sourceData) {
        this.sourceData.push(item);
      }
      this.$refs.luoshaTable && this.$refs.luoshaTable.init(draft.luoshaData);
      this.$refs.typeTable && this.$refs.typeTable.init(draft.typeData);
      this.$refs.userTable && this.$refs.userTable.init(draft.userData);
      this.$refs.qingshuTable &&
        this.$refs.qingshuTable.init(
          draft.qingshuData,
          this.mode === "edit" ? draft.paibaoData : ""
        );
      this.totalCount();
    },
    //获取今日年月日
    getToday() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    //更改工序
    changeProcess(newValue) {
      if (this.base.process !== newValue) {
        this.$confirm(
          `确认切换到${newValue}？切换工序后所有数据重新读取`,
          "温馨提示",
          {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            this.base.process = newValue;
            if (this.mode === "edit") {
              this.changeDate();
            } else {
              this.init();
            }
          })
          .catch(() => {});
      }
    },
    //更改班次
    changeShift(newValue) {
      if (this.base.shift !== newValue) {
        this.$confirm(`确认切换到${newValue}？`, "温馨提示", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.base.shift = newValue;
            if (this.mode === "edit") {
              this.changeDate();
            }
          })
          .catch(() => {});
      }
    },
    // 回车自动跳转到下一个交班
    handleEnter(i, cls) {
      if (i + 1 < this.sourceData.length) {
        if (cls === "table-input1") {
          this.sourceData[i + 1].after = "";
        } else {
          this.sourceData[i + 1].before = "";
        }
        document.getElementsByClassName(cls)[i + 1].focus();
      }
    },
    //获取记录列表
    getRecordList() {
      return new Promise((resolve) => {
        this.$ajax
          .post("mrbcshbDatas", {
            gx: this.base.process,
            pageSize: 10,
            rq_order: "-"
          })
          .then((res) => {
            if (res.records.length) {
              let lastItem = res.records[0];
              if (lastItem.bc === "3-晚班") {
                this.base.date = new dayjs(lastItem.rq)
                  .add(1, "day")
                  .format("YYYY-MM-DD");
                this.base.shift = "1-早班";
              } else {
                this.base.date = new dayjs(lastItem.rq).format("YYYY-MM-DD");
                if (lastItem.bc === "1-早班") {
                  this.base.shift = "2-中班";
                } else {
                  this.base.shift = "3-晚班";
                }
              }
            }
            this.recordList = res.records;
            resolve("finish");
          });
      });
    },
    //从远端数据中初始化
    initFromRemote() {
      this.sourceData.splice(0, this.sourceData.length);
      if (this.recordList.length) {
        let lastData = JSON.parse(this.recordList[0].dbxx);
        let lastSameData =
          this.recordList.find((item) => {
            return item.bc === this.base.shift;
          }) || this.recordList[0];
        lastSameData = JSON.parse(lastSameData.dbxx);
        for (let item of lastData.sourceData) {
          item.before = this.base.process === "5-自络" ? 0 : item.after;
          item.after = "";
          item.distributions = [];
          this.sourceData.push(item);
        }

        this.$refs.luoshaTable && this.$refs.luoshaTable.init();
        this.$refs.userTable && this.$refs.userTable.init();
        this.$refs.typeTable &&
          this.$refs.typeTable.init(lastData.typeData) &&
          this.typeChange(lastSameData.typeData);
        this.$refs.qingshuTable &&
          this.$refs.qingshuTable.init(lastData.qingshuData) &&
          this.qingshuChange(lastData.qingshuData);
      } else {
        this.sourceData = [];
        for (
          let i = 0;
          i <
          this.processList.find((item) => {
            return item.value === this.base.process;
          }).number;
          i++
        ) {
          this.sourceData.push({
            no: i + 1,
            before: 0,
            after: "",
            type: "",
            distributions: [],
          });
        }
        this.$refs.luoshaTable && this.$refs.luoshaTable.init();
        this.$refs.typeTable && this.$refs.typeTable.init();
        this.$refs.userTable && this.$refs.userTable.init();
        this.$refs.qingshuTable && this.$refs.qingshuTable.init();
      }
    },

    //获取员工列表
    getUserList() {
      return new Promise((resolve) => {
        this.$ajax
          .post("ygcygzDatas", {
            all: true,
          })
          .then((res) => {
            this.userList = {};
            for (let item of res.records.filter((rItem) => {
              return (
                rItem.beizhu &&
                rItem.beizhu
                  .join(",")
                  .indexOf(this.base.process.split("-")[1]) > -1
              );
            })) {
              this.userList[item.ygmc] = {
                class: item.bz,
                name: item.ygmc,
                work: item.beizhu,
              };
            }
            resolve("finish");
          });
      });
    },
    //获取工序
    getProcessList() {
      return new Promise((resolve) => {
        this.$ajax
          .post("gxDatas", {
            gxmc_order: "+",
          })
          .then((res) => {
            this.processList = res.records.map((item) => {
              return {
                value: item.gxmc,
                label: item.gxmc,
                number: item.sbsl,
              };
            });
            resolve("finish");
          });
      });
    },
    //获取班次
    getShiftList() {
      return new Promise((resolve) => {
        this.$ajax
          .post("bcDatas", {
            bc_order: "+",
          })
          .then((res) => {
            this.shiftList = res.records.map((item) => {
              return {
                value: item.bc,
                label: item.bc,
              };
            });
            this.base.shift = this.base.shift
              ? this.base.shift
              : this.shiftList[0].value;
            resolve("finish");
          });
      });
    },
    //获取种类
    getTypeList() {
      let colorList = [
        "#FCF6E4",
        "#EDF9DA",
        "#E8F9E7",
        "#E1F6F7",
        "#E7F3FF",
        "#E7E7FF",
        "#F5E7FF",
        "#FCE4F4",
        "#FFEAEA",
        "#FFF2EA",
      ];
      return new Promise((resolve) => {
        this.$ajax
          .post(this.typeToAddr[this.base.process], {
            sfsx: "是",
          })
          .then((res) => {
            let data = res.records;
            for (let i = 0; i < data.length; i++) {
              data[i].color = colorList[i % colorList.length];
            }
            this.typeList = data;
            resolve("finish");
          });
      });
    },
    //更改设备数量
    changeNumber() {
      this.$prompt("设备数调整为", "调整设备数", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        inputPattern: /^[1-9]\d*$/,
        inputErrorMessage: "请输入正整数",
      })
        .then(({ value }) => {
          if (value < this.sourceData.length) {
            this.sourceData = this.sourceData.slice(0, value);
          } else {
            const baseLength = this.sourceData.length;
            let number = value - baseLength;
            for (let i = 0; i < number; i++) {
              this.sourceData.push({
                no: baseLength + i + 1,
                before: 0,
                after: "",
                type: "",
                distributions: [],
              });
            }
          }

          this.$refs.typeTable.handleChange();
          this.$refs.userTable.handleChange();
        })
        .catch(() => {});
    },
    //排包提示
    paibaoTip() {
      return new Promise((resolve) => {
        this.$confirm(`未设置任何排包分配？是否继续上传`, "提示", {
          confirmButtonText: "上传",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            resolve(true);
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
    //最后提交
    lastSubmit() {
      this.$confirm(
        `<div>校验${this.hasErr ? "不" : ""}通过：人员总产量与品种总产量${
          this.hasErr ? "不" : ""
        }相等</div> <div>确定要上传数据吗</div>`,
        "审核确认",
        {
          confirmButtonText: "上传",
          cancelButtonText: "取消",
          type: this.hasErr ? "warning" : "success",
          dangerouslyUseHTMLString: true,
        }
      )
        .then(async () => {
          if (this.mode === "edit") {
            await this.$ajax.post("delOldData", {
              rq: new Date(this.base.date + " 00:00:00").getTime(),
              bc: this.base.shift,
              gx: this.base.process,
            });
          }

          if (this.base.process === "1-清梳") {
            let paibaoData = this.$refs.qingshuTable.getPaibaoData();
            if (!paibaoData.typeList.length || !paibaoData.userList.length) {
              let success = await this.paibaoTip();
              if (!success) {
                return;
              }
            }
          }

          let sourceData = JSON.parse(JSON.stringify(this.sourceData));
          if (!(this.recordModeCount > 6)) {
            await this.submitMoneyAndCl();
          }
          for (let item of sourceData) {
            if (!item.after) {
              item.after = item.before;
            }
          }

          this.$ajax
            .post("save/mrbcshbSaveFormData", {
              items: [
                {
                  bc: this.base.shift,
                  gx: this.base.process,
                  rq: new Date(this.base.date + " 00:00:00").getTime(),
                  dbxx: JSON.stringify({
                    sourceData,
                    userData: this.$refs.userTable
                      ? this.$refs.userTable.userData
                      : [],
                    typeData: this.$refs.typeTable
                      ? this.$refs.typeTable.typeData
                      : [],
                    luoshaData: this.$refs.luoshaTable
                      ? this.$refs.luoshaTable.luoshaData
                      : [],
                    qingshuData: this.$refs.qingshuTable
                      ? this.$refs.qingshuTable.qingshuData
                      : [],
                    paibaoData: this.$refs.qingshuTable
                      ? this.$refs.qingshuTable.paibaoData
                      : [],
                  }),
                },
              ],
            })
            .then(async () => {
              this.$message({
                type: "success",
                message: "上传成功!",
              });
              localStorage.setItem("draft" + this.base.process, "");
              this.submitShow = false;

              await this.getRecordList();
              this.initFromRemote();
            });
        })
        .catch(() => {});
    },
    //提交工资与产量
    submitMoneyAndCl() {
      const ms = this.base.process === "1-清梳" ? "梳棉" : "挡车";
      return new Promise((resolve) => {
        let sourceData = JSON.parse(JSON.stringify(this.sourceData));
        let moneyItems = [];
        let lsMoneyItems = [];
        let qhMoneyItems = [];
        let pbMoneyItems = [];
        let clItems = [];

        let rq = new Date(this.base.date + " 00:00:00").getTime();

        for (let item of sourceData) {
          let publicProportion = 1;
          for (let dItem of item.distributions) {
            publicProportion -= dItem.proportion;
          }
          if (publicProportion > 0) {
            item.distributions.push({
              user: "",
              proportion: publicProportion,
            });
          }
          for (let dItem of item.distributions) {
            let mateTypeItem = this.typeList.find((tItem) => {
              return tItem.pz === item.type;
            });
            let finalItem = {
              ms,
              gx: this.base.process,
              pz: item.type,
              rq,
              bc: this.base.shift,
              sbbh: item.no,
              jbcl: item.after || item.before,
              abcl: item.before,
            };
            finalItem.cl = (finalItem.jbcl - finalItem.abcl) * dItem.proportion;
            if (this.base.process === "4-细纱") {
              finalItem.gjcl =
                finalItem.cl *
                (mateTypeItem.ds / 1000) *
                (mateTypeItem.xshs / 1000);
            }
            if (this.base.process === "3-粗纱") {
              finalItem.gjcl =
                finalItem.cl *
                (mateTypeItem.ds / 10) *
                (mateTypeItem.csdl / 1000);
            }
            if (this.base.process === "2-并条") {
              finalItem.gjcl =
                finalItem.cl *
                100 *
                (mateTypeItem.mbdl / 5) *
                (mateTypeItem.ys / 1000);
            }
            if (this.base.process === "5-自络") {
              finalItem.gjcl = finalItem.cl;
            }
            if (this.base.process === "1-清梳") {
              finalItem.gjcl =
                (finalItem.cl * 1000 * mateTypeItem.smdl) / 5 / 1000;
            }
            finalItem.yg = dItem.user || "";
            finalItem.fpbl = dItem.proportion;
            clItems.push(finalItem);
          }
        }

        //梳棉和挡车 工资item
        for (let userItem of this.countByUser) {
          let mateTypeItem = this.typeList.find((tItem) => {
            return tItem.pz === userItem.type;
          });

          moneyItems.push({
            dj: mateTypeItem.dcgcldj,
            bc: this.base.shift,
            gz: userItem.money,
            cy: userItem.user,
            ms,
            cl: userItem.count,
            lx: this.base.process,
            rq,
            bz: "品种：" + userItem.type,
          });
        }

        if (this.base.process === "4-细纱") {
          let lscy = this.$refs.luoshaTable.luoshaData.map((lItem) => {
            return lItem.user;
          });
          let lsjlcy = this.$refs.luoshaTable.luoshaData
            .filter((lItem) => {
              return lItem.enable;
            })
            .map((lItem) => {
              return lItem.user;
            });

          let gz = 0;

          let bz = [];
          for (let i = 0; i < this.countByType.length - 1; i++) {
            let dj = this.typeList.find((tItem) => {
              return tItem.pz === this.countByType[i].type;
            }).lsgcldj;
            gz += this.countByType[i].count * dj;
            bz.push({
              type: this.countByType[i].type,
              cl: this.countByType[i].count / lscy.length,
              dj,
            });
          }

          let lsMoneyItem = {
            bc: this.base.shift,
            gz: gz / lscy.length,
            cy: "",
            ms: "落纱",
            cl: (
              this.countByType[this.countByType.length - 1].count / lscy.length
            ).toFixed(5),
            lx: this.base.process,
            rq,
            bz: bz
              .map((item) => {
                return `品种：${item.type} | 产量：${item.cl} | 单价：${item.dj}`;
              })
              .join("\n"),
          };

          for (let cy of lsjlcy) {
            lsMoneyItems.push({
              ...lsMoneyItem,
              cy,
            });
          }
        }

        if (this.base.process === "1-清梳") {
          let qingshuData = this.$refs.qingshuTable.qingshuData;
          for (let item of qingshuData) {
            let clItem = {
              abcl: 0,
              bc: this.base.shift,
              cl: 0,
              fpbl: 1,
              gjcl: 0,
              gx: "1-清梳",
              jbcl: 0,
              ms: "清花",
              pz: item.type,
              rq,
              sbbh: "",
              yg: item.user,
            };
            console.log(clItem);
            let qhMoneyItem = {
              bc: this.base.shift,
              cl: 0,
              cy: item.user,
              dj: 0,
              bz: [],
              lx: "1-清梳",
              ms: "清花",
              rq,
              gz: 0,
            };
            for (let sitem of item.shuMianList) {
              for (let i = sitem.start - 1; i < sitem.end; i++) {
                if (this.sourceData[i]) {
                  clItem.abcl += clItems[i].abcl - 0;
                  clItem.cl += clItems[i].cl - 0;
                  clItem.gjcl += clItems[i].gjcl - 0;
                  clItem.jbcl += clItems[i].jbcl - 0;
                  clItem.jbcl += clItems[i].jbcl - 0;

                  if (
                    !qhMoneyItem.bz.find(
                      (bzItem) => bzItem === this.sourceData[i].type
                    )
                  ) {
                    qhMoneyItem.bz.push(this.sourceData[i].type);
                  }

                  qhMoneyItem.gz +=
                    clItems[i].cl *
                    this.typeList.find((tItem) => {
                      return tItem.pz === this.sourceData[i].type;
                    }).qhcldj *
                    (this.sourceData[i].isHalf ? 0.5 : 1);
                }
              }
            }
            clItems.push(clItem);
            qhMoneyItem.cl = clItem.cl;
            qhMoneyItem.bz = "品种：" + qhMoneyItem.bz.join("/");
            qhMoneyItem.dj = (qhMoneyItem.gz / qhMoneyItem.cl).toFixed(5);
            qhMoneyItems.push(qhMoneyItem);
          }
          let paibaoData = this.$refs.qingshuTable.getPaibaoData();
          if (paibaoData.userList.length && paibaoData.typeList.length) {
            const paibaoTypeList = this.$refs.qingshuTable.paibaoTypeList;

            for (let tItem of paibaoData.typeList) {
              let mateTypeItem = paibaoTypeList.find((item) => {
                return item.pz === tItem.type;
              });
              for (let uItem of paibaoData.userList) {
                clItems.push({
                  abcl: 0,
                  bc: this.base.shift,
                  cl: tItem.count / paibaoData.userList.length,
                  fpbl: 1,
                  gjcl: "",
                  gx: "1-清梳",
                  jbcl: tItem.count / paibaoData.userList.length,
                  ms: "排包",
                  pz: tItem.type,
                  rq,
                  sbbh: "",
                  yg: uItem.user,
                });
                pbMoneyItems.push({
                  bc: this.base.shift,
                  cl: tItem.count / paibaoData.userList.length,
                  cy: uItem.user,
                  dj: mateTypeItem.pbdj,
                  gz:
                    (tItem.count / paibaoData.userList.length) *
                    mateTypeItem.zl *
                    mateTypeItem.pbdj,
                  lx: "1-清梳",
                  ms: "排包",
                  rq,
                  bz: "每包重量：" + mateTypeItem.zl,
                });
              }
            }
          }
        }
        this.$ajax
          .post("save/clmxSaveFormData", {
            items: clItems,
          })
          .then(() => {
            this.$ajax
              .post("save/rygzmxSaveFormData", {
                items: [
                  ...moneyItems,
                  ...lsMoneyItems,
                  ...qhMoneyItems,
                  ...pbMoneyItems,
                ].map((item) => {
                  return {
                    ...item,
                    gz: item.gz.toFixed(5),
                  };
                }),
              })
              .then(() => {
                resolve("finish");
              });
          });
      });
    },
    //提交
    submit() {
      let err = null;
      if (this.base.process === "1-清梳") {
        err = this.$refs.qingshuTable.check();
      } else {
        err = this.$refs.typeTable.check() || this.$refs.userTable.check();
      }
      if (err) {
        this.$message.warning(err + "，请修正后再审核");
      } else {
        this.submitShow = true;
      }
    },
    //暂存
    save() {
      let err = null;
      if (this.base.process === "1-清梳") {
        err = this.$refs.qingshuTable.check();
      } else {
        err = this.$refs.typeTable.check() || this.$refs.userTable.check();
      }
      if (err) {
        this.$message.warning(err + "，请修正后再暂存");
      } else {
        localStorage.setItem(
          "draft" + this.base.process,
          JSON.stringify({
            sourceData: this.sourceData,
            userData: this.$refs.userTable ? this.$refs.userTable.userData : [],
            typeData: this.$refs.typeTable ? this.$refs.typeTable.typeData : [],
            luoshaData: this.$refs.luoshaTable
              ? this.$refs.luoshaTable.luoshaData
              : [],
            qingshuData: this.$refs.qingshuTable
              ? this.$refs.qingshuTable.qingshuData
              : [],
            paibaoData: this.$refs.qingshuTable
              ? this.$refs.qingshuTable.paibaoData
              : [],
          })
        );
        this.$message.success("草稿暂存成功，下次将从此处开始录入");
      }
    },
    //标识颜色
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 4) {
        let color = this.typeList.find((item) => {
          return item.pz === row.type;
        });

        if (color) {
          return `background:${color.color} !important;`;
        }
      }
    },
    //产量分配改变
    userChange(items) {
      for (let sourItem of this.sourceData) {
        sourItem.distributions = [];
      }
      for (let item of items) {
        for (let i = item.start - 1; i < item.end; i++) {
          if (this.sourceData[i]) {
            let proportion = 1;
            if (i === item.start - 1) {
              proportion = item.startAdd || 1;
            }
            if (i === item.end - 1) {
              proportion = item.endAdd || 1;
            }
            let mateItem = this.sourceData[i].distributions.find((mItem) => {
              return mItem.user === item.user;
            });
            if (mateItem) {
              mateItem.proportion += proportion;
            } else {
              this.sourceData[i].distributions.push({
                user: item.user,
                proportion,
              });
            }
          }
        }
      }
      this.totalCount();
    },
    //品种分布改变
    typeChange(items) {
      for (let sourItem of this.sourceData) {
        sourItem.type = "";
      }
      for (let item of items) {
        for (let i = item.start - 1; i < item.end; i++) {
          if (this.sourceData[i]) {
            this.sourceData[i].type = item.type;
          }
        }
      }
      this.totalCount();
    },
    //清梳改变
    qingshuChange(items) {
      for (let sourItem of this.sourceData) {
        sourItem.distributions = [];
      }
      for (let item of items) {
        for (let sitem of item.shuMianList) {
          for (let i = sitem.start - 1; i < sitem.end; i++) {
            if (this.sourceData[i]) {
              this.sourceData[i].isHalf = sitem.isHalf;
              this.sourceData[i].distributions.push({
                user: sitem.user,
                proportion: 1,
              });
            }
          }
        }
      }
      this.totalCount();
    },
    //日统计
    totalCount() {
      this.hasErr = false;
      this.countByType = [];
      this.countByUser = [];
      let totalCl = 0;

      for (let item of this.sourceData) {
        let proportion = 0;
        let count = item.after ? item.after - item.before : 0;
        let mateTypeFromTypelist = this.typeList.find((tItem) => {
          return tItem.pz === item.type;
        });
        totalCl += count;
        for (let userItem of item.distributions) {
          proportion += userItem.proportion - 0;
          let mateUserItem = this.countByUser.find((uItem) => {
            return uItem.type === item.type && userItem.user === uItem.user;
          });
          if (mateUserItem) {
            mateUserItem.count += count * userItem.proportion;
            mateUserItem.money +=
              count *
              userItem.proportion *
              mateTypeFromTypelist.dcgcldj *
              (item.isHalf ? 0.5 : 1);
          } else {
            this.countByUser.push({
              type: item.type,
              user: userItem.user,
              count: count * userItem.proportion,
              money:
                count *
                userItem.proportion *
                mateTypeFromTypelist.dcgcldj *
                (item.isHalf ? 0.5 : 1),
            });
          }
        }
        let mateTypeItem = this.countByType.find((tItem) => {
          return tItem.type === item.type;
        });
        if (mateTypeItem) {
          mateTypeItem.count += count;
          mateTypeItem.public += count * (1 - proportion);
        } else {
          this.countByType.push({
            type: item.type,
            count: count,
            public: count * (1 - proportion),
          });
        }
      }
      this.countByType.push({
        type: "总计",
        count: totalCl,
        public: 0,
      });
      if (
        this.countByType.find((item) => {
          return item.public;
        })
      ) {
        this.hasErr = true;
      }
    },
  },
};
</script>

<style lang="scss">
.source {
  background: #fff;
  border-radius: 6px;
  width: 452px;
  padding: 13px 17px 17px 17px;
  .source-title {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 11px;
  }
}
.dz-cell-style {
  text-align: center !important;
  font-size: 12px !important;
  > div {
    padding: 5px !important;
  }
}
.dz-header-cell-style {
  background: #f7f8fa !important;
  color: #171a1d;
}

.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background: #f7f8fa !important;
}
.el-table .el-table__cell {
  padding: 0 !important;
  > div {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}
</style>
<style scoped lang="scss">
.before-item {
  position: relative;
  i {
    position: absolute;
    cursor: pointer;
    color: red;
    display: none;
    top: -3px;
    right: -5px;
  }
  &:hover {
    i {
      display: block;
    }
  }
}
.table-input {
  background: transparent;
  width: 67px;
  text-align: center;
}
.tip {
  color: #747677;
  margin-bottom: 24px;
  i {
    color: #ffaa00;
    margin-right: 5px;
    font-size: 16px;
  }
}
.count {
  margin-top: 10px;
  border-radius: 6px;
  background: #fff;
  padding: 13px 29px 16px 16px;
  .title {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
    > span {
      color: #ff3131;
      font-size: 12px;
      margin-left: 8px;
    }
  }
}
.dz-flexitem-1 {
  margin-left: 10px;
}
.fixed-bar {
  padding: 0 16px;
  width: 100%;
  border-radius: 6px;
  height: 56px;
  background: #fff;
  margin-bottom: 10px;
}
</style>

<template>
  <div>
    <el-button @click="scan">扫码</el-button>
    <el-button @click="link">跳转</el-button>
  </div>
</template>

<script>
import * as dd from "dingtalk-jsapi";
export default {
  methods: {
    scan() {
      dd.scan({
        type: "qr",
        success: (res) => {
          const { text } = res;
          alert(text);
        },
        fail: () => {},
        complete: () => {},
      });
    },
    link(){
        window.location.href="https://aflow.dingtalk.com/dingtalk/mobile/homepage.htm?corpid=dingc5642ff39ad3999ba1320dcb25e91351&dd_share=false&showmenu=false&dd_progress=false&back=native&procInstId=zg7Gl0fzQ0iRnllhorsa_A00061717730585&taskId=87113559484&swfrom=onebox&dinghash=approval&dtaction=os&dd_from=onebox#approval"
    }
  },
};
</script>

<style>
</style>
<template>
  <div>
    <div id="app">
      <router-view />
    </div>
  </div>
</template>
<script>
export default {
  created() {
    if (this.$route.query.token) {
      sessionStorage.setItem('acc',this.$route.query.token);
    }
  },
};
</script>
<style>
#app {
  width: 1370px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 10px 0 8px 10px;
}
</style>

<template>
  <div class="money-export dz-flexalign-center">
    <el-date-picker
      v-model="month"
      type="month"
      value-format="yyyy-MM"
      placeholder="选择日期"
    >
    </el-date-picker>
    <el-button type="primary" style="margin-left: 10px" @click="exportData"
      >导出数据</el-button
    >

    <el-upload
      class="upload-demo"
      action=""
      :before-upload="beforeUpload"
      :show-file-list="false"
      :limit="1"
      style="margin-left: 10px"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    >
      <el-button type="primary">上传数据</el-button>
    </el-upload>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  data() {
    return {
      month: "",
    };
  },
  created() {
    this.month = new dayjs().format("YYYY-MM");
  },
  methods: {
    beforeUpload(file) {
      let formData = new FormData();
      formData.append("file", file);
      this.$ajax
        .post(`importGz`, formData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(() => {
          this.$message.success("上传成功");
        });

      return false;
    },
    exportData() {
      this.$ajax
        .get(`exportGz?date=` + this.month, {
          responseType: "blob",
        })
        .then((res) => {
          let url = window.URL.createObjectURL(
            new Blob([res], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", "工资表" + this.month); // 设置文件名
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.money-export {
  width: 100%;
  background: #fff;
  padding: 30px;
  border-radius: 3px;
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import batch from '../views/batch.vue'
import examineList from '../views/examineList.vue'
import moneyExport from '../views/moneyExport.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'batch',
    component: batch
  },
  {
    path: '/examine',
    name: 'examine',
    component: examineList
  },
  {
    path: '/moneyExport',
    name: 'moneyExport',
    component: moneyExport
  }
]

const router = new VueRouter({
  routes
})

export default router

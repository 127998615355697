<template>
  <div class="dz-flex dz-flexitem-1">
    <div class="qingshu-table">
      <div class="dz-flexalign-center top">
        <div class="title">产量分配</div>
        <div class="dz-flexitem-1">{{ codeToStr[errCode] }}</div>
      </div>
      <div class="table-th table-tr">
        <div>清花人员</div>
        <div>梳棉人员</div>
        <div>始末设备</div>
        <div>对半</div>
      </div>
      <div
        class="table-tr b-top"
        v-for="(item, index) in qingshuData"
        :key="index"
      >
        <div>
          <el-select
            v-model="item.user"
            size="small"
            filterable
            @change="handleChange"
          >
            <el-option
              v-for="(item, key) in userList"
              :key="key"
              :label="item.name"
              :value="key"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <el-select
            size="small"
            v-model="sitem.user"
            v-for="(sitem, sindex) in item.shuMianList"
            :key="sindex"
            class="sitem"
            filterable
            @change="handleChange"
          >
            <el-option
              v-for="(item, key) in userList"
              :key="key"
              :label="item.name"
              :value="key"
            />
          </el-select>
        </div>
        <div>
          <div
            class="dz-flexalign-center sitem"
            v-for="(sitem, sindex) in item.shuMianList"
            :key="sindex"
            :class="{ error: sitem.errCode }"
          >
            <el-input
              v-model="sitem.start"
              size="small"
              class="dz-input-number dz-flexitem-1"
              @input="handleInput(index, sindex, 'start')"
              @change="handleChange"
            >
            </el-input>
            <span style="color: #e5e6e8; margin: 0 2px">-</span>
            <el-input
              v-model="sitem.end"
              size="small"
              class="dz-input-number dz-flexitem-1"
              @input="handleInput(index, sindex, 'end')"
              @change="handleChange"
            >
            </el-input>
            <div style="width: 37px">
              <el-checkbox v-model="sitem.isHalf" @change="handleChange" />
            </div>

            <i class="iconfont icon-jianhao" @click="del(index, sindex)"></i>
          </div>
        </div>

        <el-button size="small" class="add-qingmian" @click="reset(index)"
          >+新增梳棉</el-button
        >
      </div>
      <div class="add-btn" @click="reset('')">+新增</div>
    </div>

    <div class="dz-flexitem-1 paibao-table">
      <div class="top">
        <div class="title">排包分配</div>
      </div>
      <div class="table-th table-tr">
        <div>种类</div>
        <div>数量</div>
      </div>
      <div
        class="table-tr"
        v-for="(item, index) in paibaoData.typeList"
        :key="index"
      >
        <div>
          <el-select
            v-model="item.type"
            size="small"
            filterable
            placeholder="选择品种"
          >
            <el-option
              v-for="(item, index) in paibaoTypeList"
              :key="index"
              :label="item.pz"
              :value="item.pz"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <el-input
            v-model="item.count"
            type="number"
            size="small"
            class="dz-input-number"
          />
        </div>
        <i
          class="iconfont icon-jianhao"
          @click="paibaoData.typeList.splice(index, 1)"
        ></i>
      </div>

      <div
        class="add-btn"
        @click="
          paibaoData.typeList.push({
            type: '',
            count: 1,
          })
        "
      >
        +新增
      </div>

      <div class="title" style="margin-top: 10px">人员分配</div>
      <div
        class="user-list"
        style="margin-top: 10px"
        v-if="paibaoData.userList.length"
      >
        <div
          v-for="(item, index) in paibaoData.userList"
          :key="index"
          class="user-item"
          @click="item.enable = !item.enable"
          :class="{ actived: item.enable }"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <el-dialog
      :title="`新增${addForm.qinghuaIndex === '' ? '清花' : '梳棉'}`"
      :visible.sync="show"
      width="300px"
      :close-on-click-modal="false"
    >
      <el-form label-width="100px">
        <el-form-item label="清花人员" v-if="addForm.qinghuaIndex === ''">
          <el-select
            v-model="addForm.user"
            placeholder="请选择清花人员"
            filterable
          >
            <el-option
              v-for="(item, key) in userList"
              :key="key"
              :label="item.name"
              :value="key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="梳棉人员">
          <el-select
            v-model="addForm.shumianUser"
            placeholder="请选择梳棉人员"
            filterable
          >
            <el-option
              v-for="(item, key) in userList"
              :key="key"
              :label="item.name"
              :value="key"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="开始设备号">
          <el-input
            v-model="addForm.start"
            @input="handleAddInput('start')"
            placeholder="请输入开始设备号"
          ></el-input>
        </el-form-item>
        <el-form-item label="结束设备号">
          <el-input
            v-model="addForm.end"
            @input="handleAddInput('end')"
            placeholder="请输入结束设备号"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button @click="show = false">取消</el-button>
          <el-button type="primary" @click="confirm">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["userList", "typeList", "sourceData"],
  data() {
    return {
      paibaoTypeList: [],
      codeToStr: {
        1: "起始值大于结束值",
        2: "设备重复分布",
        3: "有设备未选择品种",
        4: "数据格式不正确",
      },
      errCode: 0,
      qingshuData: [],
      show: false,
      addForm: {
        qinghuaIndex: "",
        shumianUser: "",
        user: "",
        type: "",
        start: "",
        end: "",
      },
      paibaoData: {
        typeList: [],
        userList: [],
      },
      addUserList: [],
    };
  },
  methods: {
    getPaibaoData() {
      return {
        typeList: this.paibaoData.typeList.filter((item) => {
          return item.type && item.count;
        }),
        userList: this.paibaoData.userList.filter((item) => {
          return item.enable;
        }),
      };
    },
    init(data, paibaoData) {
      this.qingshuData = data || [];

      this.paibaoData = paibaoData
        ? {
            typeList: paibaoData.typeList,
            userList: [],
          }
        : {
            typeList: [],
            userList: [],
          };

      for (let key in this.userList) {
        if (this.userList[key].work.join(",").indexOf("排包") > -1) {
          this.paibaoData.userList.push({
            name: key,
            user: key,
            enable: false,
          });
        }
      }

      this.handleChange();
    },
    check() {
      return this.errCode ? this.codeToStr[this.errCode] : null;
    },
    getPaibaoList() {
      this.$ajax.post("pbdeDatas").then((res) => {
        this.paibaoTypeList = res.records;
      });
    },
    del(index, sindex) {
      this.$confirm("是否删除该梳棉?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.qingshuData[index].shuMianList.splice(sindex, 1);
          if (!this.qingshuData[index].shuMianList.length) {
            this.qingshuData.splice(index, 1);
          }
          this.handleChange();
        })
        .catch(() => {});
    },
    confirm() {
      if (!this.addForm.user && this.addForm.qinghuaIndex === "") {
        this.$message.error("请选择清花人员");
        return;
      }
      if (!this.addForm.shumianUser) {
        this.$message.error("请选择梳棉人员");
        return;
      }
      if (!this.addForm.start) {
        this.$message.error("请输入开始设备号");
        return;
      }
      if (!this.addForm.end) {
        this.$message.error("请输入结束设备号");
        return;
      }
      if (this.addForm.start - 0 > this.addForm.end - 0) {
        this.$message.error("起始值大于结束值");
        return;
      }
      if (this.addForm.qinghuaIndex !== "") {
        this.qingshuData[this.addForm.qinghuaIndex].shuMianList.push({
          errCode: 0,
          user: this.addForm.shumianUser,
          start: this.addForm.start,
          end: this.addForm.end,
          isHalf: false,
        });
      } else {
        this.qingshuData.push({
          type: this.addForm.type,
          user: this.addForm.user,
          shuMianList: [
            {
              errCode: 0,
              user: this.addForm.shumianUser,
              start: this.addForm.start,
              end: this.addForm.end,
              isHalf: false,
            },
          ],
        });
      }

      this.handleChange();
      this.show = false;
    },
    reset(qinghuaIndex) {
      this.addForm = {
        qinghuaIndex,
        shumianUser: "",
        user: "",
        type: "",
        start: "",
        end: "",
      };
      this.show = true;
    },
    handleAddInput(key) {
      this.addForm[key] = this.addForm[key].replace(/[^0-9]/g, "");
    },
    handleChange() {
      this.errCode = 0;
      let sItems = [];
      for (let item of this.qingshuData) {
        for (let sitem of item.shuMianList) {
          sItems.push(sitem);
        }
      }
      for (let i = 0; i < sItems.length; i++) {
        let item = sItems[i];
        item.errCode = 0;
        if (!item.start || !item.end) {
          this.errCode = item.errCode = 4;
        }

        if (item.start - 0 > item.end - 0) {
          this.errCode = item.errCode = 1;
        }
        if (i !== sItems.length - 1) {
          if (item.end - 0 + 1 != sItems[i + 1].start) {
            if (item.end - 0 >= sItems[i + 1].start) {
              this.errCode = item.errCode = 2;
            }
          }
        }
      }
      if (!this.errCode) {
        this.$emit("change", this.qingshuData);
      }
    },
    handleInput(index, sindex, key) {
      this.qingshuData[index].shuMianList[sindex][key] = this.qingshuData[
        index
      ].shuMianList[sindex][key].replace(/[^0-9]/g, "");
      if (this.qingshuData[index].shuMianList[sindex][key] == 0) {
        this.qingshuData[index].shuMianList[sindex][key] = "";
      }
    },
  },
  created() {
    this.getPaibaoList();
  },
};
</script>

<style lang="scss" scoped>
.user-list {
  overflow: hidden;
  width: 155px;
}
.user-item {
  padding: 0 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 48px;
  cursor: pointer;
  line-height: 32px;
  border-radius: 6px;
  border: 1px solid #e5e6e8;
  text-align: center;
  float: left;
  white-space: nowrap;
  font-size: 12px;
  margin-bottom: 8px;
  margin-left: 4px;
  &:nth-child(3n + 1) {
    margin-left: 0px;
  }
}
.actived {
  background: #0089ff;
  border-color: #0089ff;
  color: #fff;
}

.paibao-table {
  background: #fff;
  margin-left: 10px;
  border-radius: 6px;
  padding: 16px 33px 13px 13px;
  .table-tr {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 8px;
    width: 155px;
    > div {
      font-size: 12px;
      text-align: center;
      &:nth-child(1) {
        width: 95px;
        margin-right: 8px;
      }
      &:nth-child(2) {
        flex: 1;
      }
    }
  }
}

.top {
  margin-bottom: 10px;
  .dz-flexitem-1 {
    text-align: right;
    font-size: 12px;
    color: red;
  }
}

.title {
  font-size: 16px;
  line-height: 20px;
}

.icon-jianhao {
  position: absolute;
  right: -22px;
  cursor: pointer;
}
.add-qingmian {
  position: absolute;
  right: 0;
  bottom: 10px;
  width: 227px;
}
.qingshu-table {
  width: fit-content;
  background: #fff;
  border-radius: 6px;
  padding: 16px 33px 13px 13px;
  .table-tr {
    display: flex;
    align-items: center;
    position: relative;
    width: 325px;
    > div {
      font-size: 12px;
      text-align: center;
      &:first-child,
      &:nth-child(2) {
        width: 90px;
        margin-right: 8px;
      }

      &:nth-child(3) {
        flex: 1;
        margin-right: 8px;
      }
      &:nth-child(4) {
        width: 50px;
      }
    }
  }
}

.table-th {
  height: 32px;
  background: #f7f8fa;
  font-weight: bold;
}

.sitem {
  position: relative;
  + .sitem {
    margin-top: 5px;
  }
}

.b-top {
  padding: 10px 0 50px 0;
  + .b-top {
    border-top: 1px solid #eee;
  }
}
</style>
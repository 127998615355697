<template>
  <div style="margin-right: 10px" class="type-table">
    <div class="dz-flexalign-center top">
      <div class="title">品种分布</div>
      <div v-if="errCode" class="dz-flexitem-1">
        {{ codeToStr[errCode] }}
      </div>
    </div>

    <div class="table-th table-tr">
      <div>品种</div>
      <div>始末设备</div>
    </div>
    <div
      v-for="(item, key) in typeData"
      :key="key"
      class="table-tr"
      :class="{ error: item.errCode }"
    >
      <el-select
        v-model="item.type"
        placeholder="请选择"
        @change="handleChange()"
        style="width: 174px"
        size="small"
        filterable
      >
        <el-option
          v-for="(item, index) in typeList"
          :key="index"
          :label="item.pz"
          :value="item.pz"
        >
        </el-option>
      </el-select>
      <div>
        <el-input
          class="dz-input-number"
          v-model="item.start"
          @change="handleChange()"
          @input="handleInput(key, 'start')"
          size="small"
        ></el-input>
        <span style="color: #e5e6e8; margin: 0 2px">-</span>

        <el-input
          class="dz-input-number"
          v-model="item.end"
          @change="handleChange()"
          @input="handleInput(key, 'end')"
          size="small"
        ></el-input>
      </div>
      <i class="iconfont icon-jianhao" @click="del(key)"></i>
    </div>
    <div class="dz-flex">
      <div class="add-btn" @click="reset">+新增</div>
      <div class="add-btn" style="color: red" @click="clear">清空</div>
    </div>

    <el-dialog
      title="新增品种"
      :visible.sync="show"
      width="300px"
      :close-on-click-modal="false"
    >
      <el-form label-width="100px">
        <el-form-item label="品种名称">
          <el-select v-model="addForm.type" placeholder="请选择品种" filterable>
            <el-option
              v-for="(item, index) in typeList"
              :key="index"
              :label="item.pz"
              :value="item.pz"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始设备号">
          <el-input
            v-model="addForm.start"
            @input="handleAddInput('start')"
            placeholder="请输入开始设备号"
            @keydown.enter.native="$refs.endDec.focus()"
          ></el-input>
        </el-form-item>
        <el-form-item label="结束设备号">
          <el-input
            ref="endDec"
            v-model="addForm.end"
            @input="handleAddInput('end')"
            placeholder="请输入结束设备号"
            @keydown.enter.native="confirm"
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button @click="show = false">取消</el-button>
          <el-button type="primary" @click="confirm">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["typeList", "sourceData"],
  data() {
    return {
      addForm: {
        type: "",
        start: "",
        end: "",
      },
      show: false,
      errCode: 0,
      codeToStr: {
        1: "起始值大于结束值",
        2: "设备重复分布",
        3: "有设备未选择品种",
        4: "数据格式不正确",
      },
      typeData: [],
    };
  },
  methods: {
    clear() {
      this.$confirm("是否清空所有品种?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.typeData = [];
          this.handleChange();
        })
        .catch(() => {});
    },
    //初始化
    init(data) {
      this.typeData = data || [];
      this.handleChange();
    },
    check() {
      return this.errCode ? this.codeToStr[this.errCode] : null;
    },
    del(i) {
      this.$confirm("是否删除该品种?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.typeData.splice(i, 1);
          this.handleChange();
        })
        .catch(() => {});
    },
    confirm() {
      if (!this.addForm.type) {
        this.$message.error("请选择品种");
        return;
      }
      if (!this.addForm.start) {
        this.$message.error("请输入开始设备号");
        return;
      }
      if (!this.addForm.end) {
        this.$message.error("请输入结束设备号");
        return;
      }
      if (this.addForm.start - 0 > this.addForm.end - 0) {
        this.$message.error("起始值大于结束值");
        return;
      }
      this.typeData.push({ ...this.addForm, errCode: 0 });
      this.handleChange();
      this.show = false;
    },
    reset() {
      this.addForm = {
        type: "",
        start: "",
        end: "",
      };
      this.show = true;
    },
    handleAddInput(key) {
      this.addForm[key] = this.addForm[key].replace(/[^0-9]/g, "");
    },
    handleInput(index, key) {
      this.typeData[index][key] = this.typeData[index][key].replace(
        /[^0-9]/g,
        ""
      );
      if (this.typeData[index][key] == 0) {
        this.typeData[index][key] = "";
      }
    },
    handleChange() {
      this.errCode = 0;
      for (let i = 0; i < this.typeData.length; i++) {
        let item = this.typeData[i];
        item.errCode = 0;

        if (!item.start || !item.end) {
          this.errCode = item.errCode = 4;
        }

        if (item.start - 0 > item.end - 0) {
          this.errCode = item.errCode = 1;
        }
        if (i === this.typeData.length - 1) {
          if (item.end - 0 < this.sourceData.length) {
            this.errCode = item.errCode = 3;
          }
        } else {
          if (item.end - 0 + 1 != this.typeData[i + 1].start) {
            if (item.end - 0 >= this.typeData[i + 1].start) {
              this.errCode = item.errCode = 2;
            } else {
              this.errCode = item.errCode = 3;
            }
          }
        }
      }
      if (!this.errCode) {
        this.$emit("change", this.typeData);
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.icon-jianhao {
  position: absolute;
  margin-left: 263px;
  color: #747677;
  cursor: pointer;
}
.dz-input-number {
  width: 32px;
}
.dz-flex {
  justify-content: space-between;
}
.type-table {
  width: 305px;
  background: #fff;
  padding: 13px 16px 16px 16px;
  border-radius: 6px;
  .title {
    font-size: 16px;
    line-height: 20px;
  }
  .top {
    margin-bottom: 10px;
    .dz-flexitem-1 {
      text-align: right;
      font-size: 12px;
      color: red;
    }
  }
  .table-th {
    height: 32px;
    background: #f7f8fa;
    font-weight: bold;
  }
  .table-tr {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    width: 256px;
    > div {
      font-size: 12px;

      text-align: center;
      &:first-child {
        width: 174px;
        margin-right: 8px;
      }
      &:last-child {
        flex: 1;
      }
    }
  }
}
</style>